import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import AnimationWrapper from "../components/animationWrapper"
import ContactDataSection from "../components/contactDataSection"
import ContactFormSection from "../components/contactFormSection"
import Header from "../components/header"
import Title from "../components/title"
import ContactMap from "../components/contactMap"
import ContactMapInteractive from "../components/contactMapInteractive"
import Seo from "../components/seo"

import "./kontakt.css"

const ContactPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <ContactMapInteractive contactData={data.contactData} contactProject={data.contactProject} regions={data.regions.nodes} />

      <ContactDataSection />

      <section className="contact-data-section contact-form-section page-section">
        <AnimationWrapper id="contact-form-section">
          <Title
            className="contact-section-title"
            title="Formularz kontaktowy"
            icon={undefined}
            temp={undefined}
          />
            <ContactFormSection
              contactSection={data.page.frontmatter.contact_section}
              contactGlobal={data.contactGlobal.frontmatter}
              subject="Formularz kontaktowy Czamaninek"
              name="contact"
            />
        </AnimationWrapper>
      </section>

      <section className="contact-data-section contact-form-section page-section">
        <AnimationWrapper id="contact-form-section">
          <Title
            className="contact-section-title"
            title="Reklamacje"
            icon={undefined}
            temp={undefined}
          />
            <ContactFormSection
              contactSection={data.page.frontmatter.reklamacje_section}
              contactGlobal={data.contactGlobal.frontmatter.reklamacje}
              subject="Formularz reklamacyjny Czamaninek"
              name="complains"
            />
        </AnimationWrapper>
      </section>

      <ContactMap mapUrl={data.page.frontmatter.contact_map_url} />

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kontakt" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          # image{
          #   childImageSharp{
          #     gatsbyImageData(
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
        }
        reklamacje_section{
          title
          description
          type
          fields
          submit_button_style
          # image{
          #   childImageSharp{
          #     gatsbyImageData(
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
        }
        contact_map_url
      }
    }
    contactData: allMdx(
      filter: {internal: {contentFilePath: {regex: "/contact-data/"}}}
    ) {
      nodes {
        frontmatter{
          name
          slug
          department
          order
          position
          email
          email_alt
          phone
          phone_alt
          phone_postfix
          region
          avatar {
            childImageSharp {
              gatsbyImageData(webpOptions: {quality: 95}, width: 110, height: 110)
            }
          }
        }
      }
    }
    contactProject: mdx(frontmatter: {department: {eq: "biuro-projektowe"}}) {
      frontmatter {
        name
        slug
        department
        address{
          street
          postal_code
          locality
          region
        }
        email
        phone
        phone_alt
        phone_postfix
        # avatar {
        #  childImageSharp {
        #     gatsbyImageData(webpOptions: {quality: 95}, width: 110, height: 110)
        #   }
        # }
      }
    }
    regions: allMdx(filter: {internal: {contentFilePath: {regex: "/contact-regions/"}}}) {
      nodes {
        frontmatter {
          title
          split
          contact_sales
          contact_service
          svgd
          powiaty{
            name
            split
            contact_sales
            contact_service
            miejscowosci{
              name
              contact_sales
              contact_service
            }
            svgd
            city
          }
        }
      }
    }
    contactGlobal: mdx(
      frontmatter: {slug: {eq: "contact-global"}, type: {eq: "global"}}
    ){
      frontmatter{
        name
        phone
        phone_postfix
        phone_alt
        email
        address{
          street
          postal_code
          locality
          region
        }
        reklamacje{
          name
          phone
          phone_postfix
          phone_alt
          email
        }
      }
    }
  }
`

export default ContactPage
