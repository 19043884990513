import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import Title from "./title"
import InnerSection from "./innerSection"
import ContactForm from "./contactForm"
import ContactTile from "./contactTile"

import "./contactDataSection.css"

const ContactReklamacjeSection = ({ name, subject, contactSection, contactGlobal }) => {

  const data = useStaticQuery(
    graphql`
      query {
        lion_bg: file(relativePath: {eq: "lion-contact-bg.png"}) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 95
              transformOptions: {fit: COVER}
              webpOptions: {quality: 95}
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  return (

    <div className="contact-data-wrapper">

      <Container className="contact-data-container description-department">
        <div className="contact-department-desc-wrapper">
          <AnimationWrapper className="contact-data" animationType="fadeInUp">
            <InnerSection className="contact-data">
              <div className="contact-form-container">

                <AnimationWrapper>
                  <h2 className="section-heading">{contactSection.title}</h2>
                </AnimationWrapper>

                <ContactForm
                  subject={subject}
                  name={name}
                  fields={contactSection.fields}
                  btnStyle={contactSection.submit_button_style}
                />

              </div>
              <ContactTile contact={contactGlobal} />
            </InnerSection>
          </AnimationWrapper>
        </div>
      </Container>

      <div className="lion-bg-container">
        <GatsbyImage image={data.lion_bg.childImageSharp.gatsbyImageData} />
      </div>

    </div>

  )
}
export default ContactReklamacjeSection