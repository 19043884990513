import * as React from "react"
import { useLoadScript } from "@react-google-maps/api"
// import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete"
import{
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox"

import "@reach/combobox/styles.css"

const GoogleMapComponent = ({ regions, setActiveRegion, setPopupTitle, showContactResults } ) => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCg1jQWRsT9cBiZnSWv6UIUn-8vveTdhso",
    language: "pl",
    libraries: [
      "places",
      "geometry"
    ],
  });

  if (!isLoaded) return <div>Loading...</div>
  return <Map regions={regions} setActiveRegion={setActiveRegion} setPopupTitle={setPopupTitle} showContactResults={showContactResults} />
}

function Map( { regions, setActiveRegion, setPopupTitle, showContactResults } ){

  return (
      <div className="places-container">
        <PlacesAutocomplete regions={regions} setActiveRegion={setActiveRegion} setPopupTitle={setPopupTitle} showContactResults={showContactResults} />
      </div>
  )
}

const PlacesAutocomplete = ({ regions, setActiveRegion, setPopupTitle, showContactResults }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: {status, data},
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['postal_code', 'street_address', 'street_number', 'route', 'locality'],
      language: 'pl',
      componentRestrictions: { country: 'pl' },
    },
    debounce: 300,
  });


  const handleSelect = async (address) => {

    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({address});
    // const {lat, lng} = await getLatLng(results[0])

    // console.log("results: ",  results);
    // console.log("place_id: ", results[0]['place_id']);

    let voivodship, powiat, miejscowosc;
    for (let i = 0; i < results[0].address_components.length; i++) {
      for (let j = 0; j < results[0].address_components[i].types.length; j++) {
        switch (results[0].address_components[i].types[j]) {
          case "administrative_area_level_1":
            voivodship = results[0].address_components[i].short_name;
            break;
          case "administrative_area_level_2":
            powiat = results[0].address_components[i].short_name;
            break;
          case "locality":
            miejscowosc = results[0].address_components[i].long_name;
            break;
          default:
            break;
        }
      }
    }

    if (voivodship){
      voivodship = voivodship.replace('Województwo ','').toLowerCase();
    }
    if (powiat){
      powiat = powiat.replace('Powiat ','');
    }

    // console.log("voivodship: ", voivodship);
    // console.log("powiat: ", powiat);
    // console.log("miejscowosc: ", miejscowosc);

    let matchingRegion = null;

    // console.log('regions', regions);

    if (!voivodship){
      Object.keys(regions).forEach(function(voivodshipName){
        for (const contact of Object.values(regions[voivodshipName])) {
          for (const region of contact.regiony) {
            if (region.type === 'miejscowosc' && region.name === miejscowosc) {
              voivodship = voivodshipName;
              matchingRegion = { type: 'miejscowosc', voivodship, powiat, miejscowosc };
              break; // Exit the loop if a matching "miejscowosc" is found
            }
          }
        }
      });
    } else {
      Object.keys(regions).forEach(function(voivodshipName){
        if(voivodship === voivodshipName){
          for (const contact of Object.values(regions[voivodship])) {
            for (const region of contact.regiony) {
              if (region.type === 'miejscowosc' && region.name === miejscowosc) {
                matchingRegion = { type: 'miejscowosc', voivodship, powiat, miejscowosc };
                break; // Exit the loop if a matching "miejscowosc" is found
              }
            }
          }
        }
      });
    }

    if (matchingRegion !== null) {
      setActiveRegion(matchingRegion);
    } else {
      Object.keys(regions).forEach(function(voivodshipName){
        if(voivodship === voivodshipName){
          for (const contact of Object.values(regions[voivodship])) {
            for (const region of contact.regiony) {
              if (region.type === 'powiat' && region.name === powiat) {
                matchingRegion = { type: 'powiat', voivodship, powiat, miejscowosc };
              }
            }
            if (matchingRegion !== null) {
              setActiveRegion(matchingRegion);
              break; // Exit the loop if a matching region is found
            }
          }
        } else if (matchingRegion === null){
          // set voivodship if no matching region is found
          setActiveRegion({type: 'voivodship', voivodship, powiat, miejscowosc });
        }
      });
    }
    setPopupTitle(true);
    showContactResults(true);
  }

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="search"
        placeholder="wpisz adres lub kod pocztowy..."
      />
      <ComboboxPopover style={{zIndex: 1}}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))
          }
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

export default GoogleMapComponent